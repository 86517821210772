.landing-page {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}


.landing-background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/images/jitto3.jpg'); 
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.landing-overlay {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 50px; /* Adjust as needed */
}

.landing-text-message {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-text-message1 {
  font-size: 30px;
  color: white;
  text-align: center;
  margin: 0; /* Ensure no margin affects positioning */
}

.landing-text-message2 {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-text-message2:hover {
  background-color: #0056b3;
}

.lecturer-image {
  min-width: 100px;
  height: auto;
}
